import { mapActions, mapGetters, mapMutations } from "vuex";
import { Flicking, MOVE_TYPE } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

export default {
    name: "banner",
    data() {
        return {
            MOVE_TYPE,
            autoplayInterval: null,
            autoplayIntervalSeconds: 10,
            screenWidth: window.innerWidth,
        };
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Flicking,
    },
    created() {
        this.startAutoplay();
    },
    beforeDestroy() {
        clearInterval(this.autoplayInterval);
    },
    computed: {
        ...mapGetters({
            home: "pages/page",
        }),
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({}),
        prevSlide() {
            if (!this.$refs.flicking.animating) {
                this.$refs.flicking.prev();
            }
        },
        nextSlide() {
            if (!this.$refs.flicking.animating) {
                this.$refs.flicking.next();
            }
        },
        startAutoplay() {
            this.autoplayInterval = setInterval(() => {
                if (!this.$refs.flicking.animating) {
                    this.$refs.flicking.next();
                }
            }, this.autoplayIntervalSeconds * 1000);
        },
    },
};
